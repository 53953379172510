
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// @use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// @import '../node_modules/@angular/material/theming';
// @import '@angular/material/theming';
// @import '@angular/material/core/theming/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$eWorker-primary: mat.define-palette(mat.$grey-palette);
$eWorker-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$eWorker-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$eWorker-theme: mat.define-light-theme($eWorker-primary, $eWorker-accent, $eWorker-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($eWorker-theme);
@include mat.all-component-themes($eWorker-theme);

/* You can add global styles to this file, and also import other style files ---*/
html, body {
    min-height: 100vh;
    min-width: 100vw;
    height: auto;
    margin: 0;
  }
html, body { height: 100%; }
body { margin: 0; font-family: "Roboto", "Helvetica Neue", sans-serif; }

.mat-mdc-header-cell {
  flex-direction: column;
  justify-content: center;
}

.mat-mdc-cell {
  text-align: center;
  justify-content:center;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-cell .mat-form-field-infix {
  padding: unset;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-cell .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: unset;
  width: unset;
}
.mat-mdc-cell .mat-form-field-appearance-fill {
  width: 100px;
}
// ::ng-deep .mat-vertical-stepper-header{
//   pointer-events: none !important;
// }

// @mixin custom-stepper-theme() {
//   .mat-vertical-stepper-header {
//     pointer-events: none;
//   }
// }
.btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.barge {

    --bs-badge-padding-x: 0.65em;
    --bs-badge-padding-y: 0.35em;
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: black;
    --bs-badge-border-radius: var(--bs-border-radius);
    display: inline-block;
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 1;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius);
}


.mat-vertical-stepper-header{
  pointer-events: none !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

.new-badge {
  font-weight: 700 !important;
  color: black !important;
}

a { text-decoration: none !important;}
// .mat-step-header {
//   pointer-events: none;
// }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
